import {
  Grid, Typography, useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentHome from '../Content/ContentHome';
import { getMemberInformation } from '../../store/thunks/onboardingThunk';
import { useAppDispatch } from '../../store/hooks';
import { useTracking } from '../../common/useTracking';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

export function SavedContent() {
  const dispatch = useAppDispatch();
  const trackEvent = useTracking();
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMemberInformation({ trackEvent }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OuterContainer sx={{ padding: theme.spacing(2) }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 6, sm: 6, md: 6 }}
      >
        <Typography
          variant="h12"
          sx={{
            paddingLeft: theme.spacing(3),
          }}
        >
          {t('navigation.saved')}
        </Typography>
        <ContentHome onlySavedContent contentViewBaseUrl={'/saved'} />
      </Grid>
    </OuterContainer>
  );
}
