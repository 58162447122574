/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from '@mui/material';
import { useFeatureFlags } from '../config/FeatureFlagsProvider';

export interface DashboardQuestion {
  name: string;
  summary: string;
  Detail: () => JSX.Element
}

export const dashboardQuestionsJoist: DashboardQuestion[] = [
  {
    name: 'panel1',
    summary: 'How do I earn rewards?',
    Detail: () => (<Typography>
      Use the credit card you confirmed during your signup to Joist Edge to make
      purchases at Home Depot. Show your Home Depot Pro Xtra ID at checkout, and
      your Joist Edge balance will be automatically updated.
    </Typography>),
  },
  {
    name: 'panel2',
    summary: 'How much can I earn using Joist Edge?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            The more you spend, the more you earn.
            You can receive cash back on all qualified Home Depot purchases when using
            the confirmed form of payment connected to your Home Depot Pro Xtra account.
          </Typography>
          {behrFlag && discountFlag && <Typography>
            Additionally, you can enjoy 20% off on Behr and KILZ purchases at checkout
            when you provide your Pro Xtra ID.
          </Typography>}
        </>
      );
    },
  },
  {
    name: 'panel2.5',
    summary: 'I\'m already receiving Home Depot rebates. Can I still earn through Joist Edge? ',
    Detail: () => {
      const { behrFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            Yes. There's no need to switch or choose between programs;
            you can enjoy the benefits of both. Joist Edge rewards are designed to stack with
            your current Home Depot rebates, so you can earn even more on your eligible
            purchases.
          </Typography>
          {behrFlag && <Typography>
            It will includes the Behr and KILZ discounts.
          </Typography>}
      </>
      );
    },
  },
  {
    name: 'panel3',
    summary: 'What purchases count toward Joist Edge rewards?',
    Detail: () => (<Typography>
      Any purchases made at Home Depot that qualify under their Pro Xtra program
      will earn cash back. Exceptions under Pro Xtra include sales tax,
      shipping costs, and some other incidentals. Exclusions on items may apply.
    </Typography>),
  },
  {
    name: 'panel4',
    summary: 'Is there a minimum purchase required to qualify for rewards?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
        <Box>
          <Typography>
            Yes, Home Depot requires a minimum spend of $12,500 per
            6-month period to qualify for rewards.
            The qualifying periods are January 1 – June 30 and July 1 – December 31.
            Please note that these requirements are subject to change.
          </Typography>
          <br></br>
          {behrFlag && discountFlag && <Typography>
            However, there is no minimum purchase required to receive Behr and KILZ rewards
            or the 20% discount on Behr and KILZ products. You can start enjoying the discount
            immediately upon enrolling and linking your Pro Xtra account.
          </Typography>}
        </Box>
      );
    },
  },
  {
    name: 'panel5',
    summary: 'When will I receive my Joist Edge money?',
    Detail: () => (<Box>
      <Typography>Joist Edge rewards are paid out twice a year.</Typography>
      <ul>
        <li key={1}>For purchases made between January 1 and June 30,
          you should receive your reward funds in late summer/early fall.</li>
        <li key={2}>For purchases made between July 1 and December 31,
          you should receive your reward funds in late winter/early spring.</li>
      </ul>
      <Typography>While we strive to process payouts promptly,
        the exact timing may vary.</Typography>
    </Box>),
  },
  {
    name: 'panel6',
    summary: 'Where will I receive my Joist Edge money?',
    Detail: () => (
      <Typography>We'll send your rewards to your verified PayPal Business Account.
        Don't have one? They're free and easy to set up at &nbsp;
        <a href={'https://www.paypal.com/business/getting-started'} target={'_blank'} rel="noreferrer">
          https://www.paypal.com/business/getting-started
        </a>
        .</Typography>
    ),
  },
  {
    name: 'panel7',
    summary: 'How do I get my Joist Edge money?',
    Detail: () => (
      <Box>
        <Typography component={'div'}>
          <span style={{ fontWeight: 'bolder' }}>If you use Joist Payments: </span>
          Enter your PayPal Business Account email on your rewards page,
          and your reward money will be sent there.
        </Typography>
        <Typography component={'div'} sx={{ mt: 2 }}>
          <span style={{ fontWeight: 'bolder' }}>If you have a legacy Joist Payments account through WePay: </span>
          You will need to create a PayPal Business Account.
        </Typography>
        <Typography component={'div'} sx={{ mt: 2 }}>
          <span style={{ fontWeight: 'bolder' }}>If you don't use Joist Payments: </span>
          Contact us at&nbsp;<a href={'mailto://hello@joist.com'}>hello@joist.com</a> and we'll help you get set up.
          We'll ensure you've connected your bank account to a PayPal Business Account
          and enabled automatic transfers.
        </Typography>
      </Box>
    ),
  },
];

export const SFDashboardQuestionsHDOnly: DashboardQuestion[] = [
  {
    name: 'panel1',
    summary: 'How do I earn rewards?',
    Detail: () => (<Typography>
      Use the credit card you confirmed during your signup to Service Fusion Edge to make
      purchases at Home Depot. Show your Home Depot Pro Xtra ID at checkout,
      and your Service Fusion Edge balance will be automatically updated.
    </Typography>),
  },
  {
    name: 'panel2',
    summary: 'How much can I earn using Service Fusion Edge?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            The more you spend, the more you earn. You can receive cash back on qualified
            Home Depot purchases when using the confirmed form of payment connected to your
            Home Depot Pro Xtra account.
          </Typography>
          {behrFlag && discountFlag && <Typography>
            Additionally, you can enjoy 20% off on Behr and KILZ purchases at
            checkout when you provide your Pro Xtra ID.
          </Typography>}
        </>
      );
    },
  },
  {
    name: 'panel3',
    summary: 'I\'m already receiving Home Depot rebates. Can I still earn through Service Fusion Edge?',
    Detail: () => {
      const { behrFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            Yes. There's no need to switch or choose between programs;
            you can enjoy the benefits of both. Service Fusion Edge rewards are
            designed to stack with your current Home Depot rebates,
            so you can earn even more on your eligible purchases.
          </Typography>
          {behrFlag && <Typography>
            It will includes the Behr and KILZ discounts.
          </Typography>}
        </>
      );
    },
  },
  {
    name: 'panel4',
    summary: 'What purchases count toward Service Fusion Edge rewards?',
    Detail: () => (
      <Typography> Any purchases made at Home Depot that qualify under their Pro Xtra program
        will earn cash back. Exceptions under Pro Xtra include sales tax, shipping costs,
        and some other incidentals. Exclusions on items may apply.
      </Typography>
    ),
  },
  {
    name: 'panel5',
    summary: 'Is there a minimum purchase required to qualify for rewards?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
        <Box>
          <Typography> Yes, Home Depot requires a minimum spend of $12,500 per 6-month period to
            qualify for rewards. The qualifying periods are January 1 – June 30 and
            July 1 – December 31. Please note that these requirements are subject to change.
          </Typography>
          <br></br>
          {behrFlag && discountFlag && <Typography>
            However, there is no minimum purchase required to receive Behr and KILZ rewards
            or the 20% discount on Behr and KILZ products. You can start enjoying the discount
            immediately upon enrolling and linking your Pro Xtra account.
          </Typography>}
        </Box>
      );
    },
  },
  {
    name: 'panel6',
    summary: 'When will I receive my Service Fusion Edge money?',
    Detail: () => (
      <Box>
        <Typography>Service Fusion Edge rewards are paid out twice a year.
        </Typography>
        <ul>
          <li key={1}>
            For purchases made between January 1 and June 30,
            you should receive your reward funds in late summer/early fall.
          </li>
          <li key={2}>
            For purchases made between July 1 and December 31,
            you should receive your reward funds in late winter/early spring.

          </li>
        </ul>
        While we strive to process payouts promptly, the exact timing may vary.
      </Box>
    ),
  },
  {
    name: 'panel7',
    summary: 'Where will I receive my Service Fusion Edge money?',
    Detail: () => (
      <Typography component={'div'}>We'll send your rewards to your verified PayPal Business Account.
        Don't have one? They're free and easy to set up at &nbsp;
        <a href={'https://www.paypal.com/business/getting-started'} target={'_blank'} rel="noreferrer">
          https://www.paypal.com/business/getting-started
        </a>.
      </Typography>
    ),
  },
];

export const SFDashboardQuestions: DashboardQuestion[] = [
  {
    name: 'panel1',
    summary: 'How do I earn a rebate as a member of Service Fusion Edge?',
    Detail: () => (<Typography>
      It's simple! Once you enroll with one of our partnered suppliers,
      they'll track your eligible purchases throughout the quarter. We'll handle the rest,
      making sure you receive your rebates.
    </Typography>),
  },
  {
    name: 'panel2',
    summary: 'What purchases qualify for rebates?',
    Detail: () => {
      const { behrFlag } = useFeatureFlags();
      return (
      <Typography component={'div'}>
        Each supplier has its own rules, including minimum purchase amounts and product exclusions.
        See details below.
        <br></br>
        <br></br>
        Customers receiving rebates through another best practices group must request in writing
        to transfer their rebates to Service Fusion.
        <br></br>
        <br></br>
        <div><span style={{ fontWeight: '600', color: 'grey' }}>Ferguson</span></div>
        (This is a plumbing-focused program)
        <div><span style={{ fontWeight: '600' }}>Exclusions: </span>
          HVAC equipment, Generac generators, HVAC equipment, and refrigerant are excluded.
        </div>
        <div><span style={{ fontWeight: '600' }}>Qualifying Purchases: </span>
          All non-HVAC purchases qualify for a rebate if they exceed the same quarter
          the previous year.
        </div>
        <div><span style={{ fontWeight: '600' }}>Special Rebates: </span>
          Ferguson Own Brand products earn an unconditional rebate
          (growth over prior year is not required).
          Brands include FNW, Mirabelle, Monogram Brass, Park Harbor, PROFLO, PROSELECT, and Raptor.
        </div>
        <br></br>
        <div><span style={{ fontWeight: '600', color: 'grey' }}>HD Supply</span></div>
        (Formerly known as Barnett, Home Depot Pro Specialty Trades)
        <div><span style={{ fontWeight: '600' }}>Limited Exclusions: </span>
          Most purchases qualify for rebates, EXCEPT Perma-Liner equipment and
          Generac home standby generators.
        </div>
        <div><span style={{ fontWeight: '600' }}>Minimum Purchase: </span>
          A minimum purchase amount may apply to qualify for rebates.
        </div>
        <br></br>
        <div><span style={{ fontWeight: '600', color: 'grey' }}>Lennox</span></div>
        <div><span style={{ fontWeight: '600' }}>Exclusions: </span>
          Commodities (copper, refrigerant, certain duct), residential new construction
          (RNC) serialized equipment, commercial equipment, warranty product, equipment
          purchased through retailers, and equipment purchased through property management channels
          (e.g., American Homes 4 Rent, Invitation Homes). Customers part of a national account or
          another best practices group, or with an existing Lennox agreement, are ineligible.
        </div>
        <div><span style={{ fontWeight: '600' }}>Special Requirements: </span>
          Customers can request, in writing, to transfer their rebates to Service Fusion.
        </div>
        <div><span style={{ fontWeight: '600' }}>Minimum Purchase: </span>
          Customer purchases must exceed $25,000 annually, adjusted for quarterly seasonality.
          Breakdown:
          <ul>
            <li key={1}>
              $7,500 per quarter in Q2 & Q3 (high season)
            </li>
            <li key={2}>
              $5,000 per quarter in Q1 & Q4 (low season)
            </li>
          </ul>
        </div>
        <div><span style={{ fontWeight: '600' }}>Rebate Start: </span>
          Rebates begin the quarter the customer's Lennox dealer number is recorded
          on their Service Fusion profile.
        </div>
        <br></br>
        <div><span style={{ fontWeight: '600', color: 'grey' }}>PlumbMaster aka Wolverine Brass</span></div>
        <div><span style={{ fontWeight: '600' }}>Qualifying Purchases: </span>
          Rebate is based on combined purchases from PlumbMaster, Wolverine Brass, and Speakman.
        </div>
        <br></br>
        <div><span style={{ fontWeight: '600', color: 'grey' }}>WinSupply</span></div>
        <div><span style={{ fontWeight: '600' }}>Exclusions: </span>
          No product exclusions. All purchases earn rebates.
        </div>
        <div><span style={{ fontWeight: '600' }}>Account Status: </span>
          Customer's account must be in good standing to earn rebates.
          Rebates are forfeited if the account is past due
        </div>
        <div><span style={{ fontWeight: '600' }}>Eligibility: </span>
          Local stores control customer rebate eligibility. Customers with favorable
          front-end discounts or pricing can be classified as ineligible for rebates or
          eligible for growth-only rebates.
        </div>
        <br></br>
        {behrFlag && <div>
          <span style={{ fontWeight: '600', color: 'grey' }}>Behr/KILZ</span>
        </div>}
        <div>No minimum purchase. </div>
      </Typography>
      );
    },
  },
  {
    name: 'panel3',
    summary: 'I\'m already receiving rebates from a supplier. Can I still earn through Service Fusion Edge?',
    Detail: () => (<Typography>
      Suppliers typically offer only one rebate program. If you'd like to switch to
      earning rebates through Service Fusion Edge, simply inform the supplier in writing.
    </Typography>),
  },
  {
    name: 'panel4',
    summary: 'How much can I earn?',
    Detail: () => (
      <Typography> Rebate amounts vary by supplier and your spending.
        Review the specific rules for each supplier for more details.
      </Typography>
    ),
  },
  {
    name: 'panel5',
    summary: 'When will I receive my Service Fusion Edge rebate?',
    Detail: () => (
      <Box>
        <Typography>Rebates are paid out quarterly, usually within these timeframes:
        </Typography>
        <ul>
          <li key={1}>
            <div><span style={{ fontWeight: '600' }}>Purchases made between January 1 and March 31: </span>
              Rebates typically paid out in mid-summer.
            </div>
          </li>
          <li key={2}>
            <div><span style={{ fontWeight: '600' }}>Purchases made between April 1 and June 30: </span>
              Rebates typically paid out in mid-fall.
            </div>
          </li>
          <li key={3}>
            <div><span style={{ fontWeight: '600' }}>Purchases made between July 1 and September 30: </span>
              Rebates typically paid out in mid-winter.
            </div>
          </li>
          <li key={4}>
            <div><span style={{ fontWeight: '600' }}>Purchases made between October 1 and December 31: </span>
              Rebates typically paid out in mid-spring.
            </div>
          </li>
        </ul>
      </Box>
    ),
  },
  {
    name: 'panel6',
    summary: 'Where will I receive my Service Fusion Edge money?',
    Detail: () => (
      <Typography component={'div'}>We'll send your rebates to your verified PayPal Business Account.
        Don't have one? They're free and easy to set up at &nbsp;
        <a href={'https://www.paypal.com/business/getting-started'} target={'_blank'} rel="noreferrer">
          https://www.paypal.com/business/getting-started
        </a>.
      </Typography>
    ),
  },
];

export const dashboardQuestionsEverPro: DashboardQuestion[] = [
  {
    name: 'panel1',
    summary: 'How do I earn rewards?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
      <Box>
        <Typography component={'div'}>
          To start earning rewards, first ensure your accounts are connected:
        </Typography>
        <br></br>
        <Typography component={'div'}>
          <div style={{ fontWeight: 'bolder' }}>Connect Your Accounts</div>
          Make sure your Home Depot Pro Xtra account is connected to EverPro Edge.
          This process includes confirming that you have a credit card on file in
          your Pro Xtra account and enrolling in the "Joist" program under the
          National Account Incentives section.
        </Typography>
        <br></br>
        <Typography component={'div'}>
          Even if you had a Pro Xtra account before signing up for Edge,
          it’s essential to confirm that a credit card is on file in your
          Pro Xtra account and that your account is connected to Edge.
          This ensures that your purchases are tracked correctly and rewards
          are credited to your Edge account.
        </Typography>
        <br></br>
        <Typography component={'div'}>Next, when making purchases: </Typography>
        <Typography component={'div'} sx={{ mt: 2 }}>
          <div style={{ fontWeight: 'bolder' }}>In-store purchases</div>
          When making a purchase in-store, enter your Pro Xtra ID during checkout
          or scan your Pro Xtra ID from the Home Depot App or your smartphone’s mobile browser.
          {behrFlag && discountFlag && <span>
            Providing your Pro Xtra ID at checkout gives you an instant 20% off
            Behr and KILZ purchases.
          </span>}
        </Typography>
        <Typography component={'div'} sx={{ mt: 2 }}>
          <div style={{ fontWeight: 'bolder' }}>Online Purchases</div>
          When placing an order on the Home Depot website or through the app,
          ensure you’re logged into your Pro Xtra account. Use any credit card that
          is registered in your Pro Xtra account, and your Edge rewards balance will be
          automatically updated.
          {behrFlag && <span>
            Behr and KILZ product discounts will be applied when your
            Pro Xtra ID is recognized.
          </span>}
        </Typography>
      </Box>
      );
    },
  },
  {
    name: 'panel2',
    summary: 'How much can I earn using EverPro Edge?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            The more you spend, the more you earn. You can receive cash back
            on qualified Home Depot purchases when using the confirmed form of payment
            connected to your Home Depot Pro Xtra account.
          </Typography>
          {behrFlag && discountFlag && <Typography>
            Additionally, you can enjoy 20% off on
            Behr and KILZ purchases at checkout when you provide your Pro Xtra ID.
          </Typography>}
        </>
      );
    },
  },
  {
    name: 'panel3',
    summary: 'I\'m already receiving Home Depot rebates. Can I still earn through EverPro Edge? ',
    Detail: () => {
      const { behrFlag } = useFeatureFlags();
      return (
        <>
          <Typography>
            Yes. There's no need to switch or choose between programs;
            you can enjoy the benefits of both. EverPro Edge rewards are designed to
            stack with your current Home Depot rebates, so you can earn even more on
            your eligible purchases.
          </Typography>
          {behrFlag && <Typography>
            It will includes the Behr and KILZ discounts.
          </Typography>}
        </>
      );
    },
  },
  {
    name: 'panel4',
    summary: 'What purchases count toward EverPro Edge rewards?',
    Detail: () => (<Typography>
      Any purchases made at Home Depot that qualify under their Pro Xtra program
      will earn cash back. Exceptions under Pro Xtra include sales tax, shipping costs,
      and some other incidentals. Exclusions on items may apply.
    </Typography>),
  },
  {
    name: 'panel5',
    summary: 'Is there a minimum purchase required to qualify for rewards?',
    Detail: () => {
      const { behrFlag, discountFlag } = useFeatureFlags();
      return (
      <Box>
        <Typography>
          Home Depot rewards require a minimum spend of $12,500 per 6-month period to qualify.
          The qualifying periods are January 1 – June 30 and July 1 – December 31.
          Please note that these requirements are subject to change.
        </Typography>
        <br></br>
        {behrFlag && discountFlag && <Typography>
          However, there is no minimum purchase required to receive Behr and KILZ rewards
          or the 20% discount on Behr and KILZ products. You can start enjoying the discount
          immediately upon enrolling and linking your Pro Xtra account.
        </Typography>}
      </Box>
      );
    },
  },
  {
    name: 'panel6',
    summary: 'When will I receive my EverPro Edge money?',
    Detail: () => (<Box>
      <Typography>EverPro Edge rewards are paid out twice a year.</Typography>
      <ul>
        <li key={1}>For purchases made between January 1 and June 30,
          you should receive your reward funds in late summer/early fall.  </li>
        <li key={2}>For purchases made between July 1 and December 31,
          you should receive your reward funds in late winter/early spring.   </li>
      </ul>
      <Typography>While we strive to process payouts promptly,
        the exact timing may vary.</Typography>
    </Box>),
  },
  {
    name: 'panel7',
    summary: 'Where will I receive my EverPro Edge money?',
    Detail: () => (
      <Typography>We'll send your rewards to your verified PayPal Business Account.
        Don't have one? They're free and easy to set up at &nbsp;
        <a href={'https://www.paypal.com/business/getting-started'} target={'_blank'} rel="noreferrer">
          https://www.paypal.com/business/getting-started
        </a>
        .</Typography>
    ),
  },
];
