import {
  Box, Grid, Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CallToActionButton from '../../common/CallToActionButton';

interface SavedContentEmptyStateViewProps {
  fullWidthContainer?: boolean
}

export default function SavedContentEmptyStateView({
  fullWidthContainer,
}
  : SavedContentEmptyStateViewProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/home');
  };

  return (
    <Grid item
      sx={{
        paddingTop: theme.spacing(3),
        paddingLeft: fullWidthContainer ? undefined : theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(2),
          paddingLeft: fullWidthContainer ? undefined : theme.spacing(2),
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: theme.palette.common.white,
          padding: theme.spacing(2.5),
          borderRadius: theme.spacing(1),
          boxShadow: 0,
        }}
      >
        <Typography variant="h9" gutterBottom>
          {t('content.saved.emptyState.heading')}
        </Typography>
        <Typography variant="body2"
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          {t('content.saved.emptyState.description')}
        </Typography>
        <CallToActionButton
          text={t('button.goToHomeFeed') as string}
          onBtnClick={navigateToHome}
          sx={{
            background: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
            position: 'static',
            width: '100%',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: theme.spacing(3.75),
            fontWeight: '600',
            fontSize: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              width: '100%',
              marginLeft: '0px',
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
          }}
        />
      </Box>
    </Grid>
  );
}
