import * as Sentry from '@sentry/react';

export function initSentry() {
  if (!process.env.REACT_APP_SENTRY_DSN || process.env.REACT_APP_ENVIRONMENT === 'dev') {
    // Skip if SENTRY_DSN is not set. e.g. localhost
    return;
  }
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_GIT_SHA ? `optimus-react@${process.env.REACT_APP_GIT_SHA}` : undefined,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api(.*)\.optimusapp\.io/,
    ],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100%
    // while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.01,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}
